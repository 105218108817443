import request from '@/utils/request.js'

// 专家分类
export function getTeamCate(query={}) {
  return request({
    url: 'api/getTeamCate',
    method: 'POST',
    params: query
  })
}

// 专家列表
export function getExpertTeam(query={}) {
  return request({
    url: 'api/getExpertTeam',
    method: 'POST',
    params: query
  })
}

// 专家详情
export function getTeacherDetail(query={}) {
  return request({
    url: 'api/getTeacherDetail',
    method: 'POST',
    params: query
  })
}
