<template>
	<div class="m-expert" v-loading="loading">
		<!--<div class="m-where">
			<div class="u-flex u-col-top u-m-b-26" v-for="(v,k) in typeList">
				<div class="m-label">
					<span>{{v.name}}：</span>
				</div>
				<div class="u-flex u-col-top u-flex-wrap u-m-l-15">
					<span class="m-value" :class="{'m-value-active':item.id == params.cate_id}" @click="selectMenu(item)" v-for="item in v.son">{{item.name}}</span>
				</div>
			</div>
		  <div class="u-flex u-col-top u-m-b-26">
				<div class="m-label">
					<span>建设工程类：</span>
				</div>
				<div class="u-flex u-col-top u-flex-wrap u-m-l-15">
					<span class="m-value" v-for="item in 2">注册安全工程师</span>
				</div>
			</div>
			<div class="u-flex u-col-top">
				<div class="m-label">
					<span>财经类：</span>
				</div>
				<div class="u-flex u-col-top u-flex-wrap u-m-l-15">
					<span class="m-value" v-for="item in 3">注册安全工程师</span>
				</div>
			</div>
		</div>-->
		
		<div class="m-list">
			<div class="m-item" v-for="(item,index) in list" @click="to_link('/expertDetail',{id:item.id})">
				<div class="m-item-img">
					<img :src="item.image" />
				</div>
				<div class="m-item-con">
					<p class="m-item-name">{{item.name}}</p>
					<p class="m-item-text u-m-t-20 u-line-4">{{item.synopsis || ''}}</p>
					<div class="m-item-bottom">
						<span class="m-item-bottom-font1">主讲课程：</span>
						<span class="m-item-bottom-font2">{{item.master_course ? item.master_course.join('、') : '--'}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="u-flex u-row-center u-m-t-30 u-p-b-60">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  @current-change="pageChange"
			  :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {getTeamCate,getExpertTeam} from "@/api/expert.js"
	import {to_link} from "@/router/api.js"
	export default {
		data(){
			return {
				loading:false,
				typeList:[],
				params:{
					page:1,
					pageNum:10,
					cate_id:''
				},
				total:0,
				list:[]
			}
		},
		mounted() {
			this.getTypeList()
			this.getList()
		},
		methods:{
			to_link,
			selectMenu(item){
				this.params.cate_id = item.id
				this.getList()
			},
			pageChange(e){
				this.params.page = e
				this.getList()
			},
			async getTypeList(){
				let res = await getTeamCate({})
				if(res.code == 200){
					this.typeList = res.data
				}else{
					this.$message.error(res.message)
				}
			},
			async getList(){
				this.loading = true
				let res = await getExpertTeam(this.params)
				setTimeout(()=>{
					this.loading = false
				},500)
				if(res.code == 200){ 
					this.list = res.data || []
					this.total = res.count || 0
				}else{
					this.$message.error(res.message)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-expert{

		min-height: 500px;
		background-image: url(../assets/img/common/zhuanjia-bg2.png);
		background-repeat: no-repeat;
		background-size: 1920px 430px;
		padding-top: 388px;
		.m-where{
			width: 1200px;
			margin: 0 auto;
			padding: 46px 0 46px 0px;
			border-radius: 5px;
			background: #fff;
			.m-label{
				font-size: 18px;
				color: #000;
				font-weight: 800;
				text-align: right;
				width: 210px;
				flex-grow: 0;
				flex-shrink: 0;
			}
			.m-value{
				font-size: 16px;
				color: #222;
				cursor: pointer;
				margin-right: 24px;
				margin-bottom: 10px;
			}
			.m-value-active{
				color: #1b87ef;
			}
		}
		
		.m-list{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 1200px;
			margin: 0 auto;
			margin-top: 68px;
			
			.m-item{
				display: flex;
				width: 590px;
				box-sizing: border-box;
				background: #fff;
				border-radius: 5px;
				padding: 16px;
				transition: all 0.3s;
				cursor: pointer;
				margin-bottom: 42px;
				.m-item-img{
					display: flex;
					justify-content: center;
					align-items: center;
					width: 174px;
					height: 232px;
					// background: #f5f5f5;
					overflow: hidden;
					img{
						width: auto;
						height: 100%;
					}
				}
				.m-item-con{
					width: 360px;
					margin-left: 15px;
					position: relative;
					.m-item-name{
						font-size: 20px;
						color: #222;
						line-height: 20px;
						margin-top: 30px;
					}
					.m-item-text{
						font-size: 14px;
						color: #666666;
						line-height: 24px;
					}
					.m-item-bottom{
						display: flex;
						align-items: center;
						position: absolute;
						border-left: 5px solid #1b87ef;
						bottom: 30px;
						padding-left: 13px;
						.m-item-bottom-font1{
							font-size: 14px;
							color: #222222;
							line-height: 14px;
						}
						.m-item-bottom-font2{
							font-size: 14px;
							color: #666666;
							line-height: 14px;
						}
					}
				}
				
				&:hover{
					background: #42ACDC;
					box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
					.m-item-con{
						.m-item-name{
							color: #fff;
						}
						.m-item-text{
							color: #fff;
						}
						.m-item-bottom{
							border-left: 5px solid #fff;;
							.m-item-bottom-font1{
								color: #fff;
							}
							.m-item-bottom-font2{
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
</style>
